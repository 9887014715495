import { useEffect } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate, useParams } from 'react-router-dom';
import { getMessage } from '../../../multilanguageSupport/mutilanguage';
import messages from '../../../multilanguageSupport/messages/purchase.json';

const PaymentFailure = () => {
    const navigate = useNavigate();
    const { moduleName } = useParams<{ moduleName: string }>();

    useEffect(() => {
        const paymentStatus = localStorage.getItem(`paymentIntent`);
        if (!paymentStatus) {
            navigate('/cart');
        }
    }, [moduleName, navigate]);

    const handleTryAgain = () => {
        localStorage.removeItem(`paymentIntent`);
        navigate(`/cart`);
    }
    return (
        <div className="min-h-screen bg-red-50 flex flex-col justify-center items-center">
            <div className="bg-white p-10 rounded-lg shadow-lg max-w-md text-center">
                <div className='w-full flex justify-center'>
                    <IoCloseOutline size={70} color='rgb(220 38 38)' />
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-2">{getMessage("errorMessages","purchaseError", messages )}</h2>
                <p className="text-gray-600 mb-6"></p>
                <button onClick={handleTryAgain} className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75">
                    {getMessage("buttonNames","tryAgain", messages )}
                </button>
            </div>
        </div>
    );
};

export default PaymentFailure;
