import React, { useCallback, useEffect, useRef, useState } from 'react';
import Page from './Page';
import { IoAddCircle, IoHelpCircleSharp } from "react-icons/io5";
import Loading from '../components/Loading/loading';
import { useUser } from '../contexts/userContext';
import PhoneInput from '../components/PhoneInput/PhoneInput';
import { format } from 'date-fns';
import "../components/customScroll.styles.scss";
import { TeamMember } from '../interfaces/TeamMember';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import "../components/Toggle/toggle.styles.scss";
import { RiEdit2Fill } from "react-icons/ri";
import { FaCheck } from 'react-icons/fa';
import { LuImport } from 'react-icons/lu';
import { IoIosClose } from 'react-icons/io';
import Modal from '../components/Modal';
import * as XLSX from 'xlsx';
import emailjs from 'emailjs-com';
import template from "../content/template";
import { encryptData } from '../components/encrypt_decrypt';
import roles from '../content/roles';
import { clearCache, fetchData, postData, putData } from '../components/DataHandler';
import Toaster from '../components/Toaster';
import { getMessage } from '../multilanguageSupport/mutilanguage';
import messages from '../multilanguageSupport/messages/settings.json';

export default function Settings() {
    const { user, setUser } = useUser();
    const [hasCompany, setHasCompany] = useState<boolean>(false);
    const [company, setCompany] = useState<string>('');
    const [company_id, setCompany_id] = useState<string>('');
    const [nipc, setNipc] = useState<string>('');
    const [email, setEmail] = useState<string | undefined>(user!.email);
    const [phone, setPhone] = useState<string>(user!.phone || '');
    const [loading, setLoading] = useState<boolean>(false);
    const [teamCount, setTeamCount] = useState(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]); // Array of selected members to add to the team
    const [data, setData] = useState<any[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [employees, setemployees] = useState<TeamMember[]>([]);
    const [editRowVisible, setEditRowVisible] = useState<Number>(-1);
    const [selectedRole, setSelectedRole] = useState<string>('n/a');
    const [newUserEmail, setNewUserEmail] = useState<string>('');
    const today = new Date();


    const getAccountInfo = useCallback(async () => {
        const idToken = user ? user!.idToken : null;
        clearCache("/accountInfo");
        const response = await fetchData("/accountInfo", idToken);
        if (response.status === 200 || response.status === 201) {
            const employees = response.data.employees;
            setCompany(response.data.name);
            setNipc(response.data.nif);
            setHasCompany(true);
            setemployees(employees);
            setTeamCount(response.data.teamCount);
            setCompany_id(response.data.company_id);
        } else if (response.status === 204 || response.status === 404) {
            Toaster.show(getMessage("errorMessages", "accountInfoNotFound", messages), 'error');
            setHasCompany(false);
        } else {
            Toaster.show(getMessage("errorMessages", "getCompanyFailed", messages), 'error');
            console.error('Get company failed');
        }
        setLoading(false);
    }, [user]);


    const handleCompanyCreate = async () => {
        if (!validate()) {
            return;
        }
        const idToken = user ? user!.idToken : null;

        setLoading(true);
        const companyObj = {
            name: company,
            nif: nipc,
        }
        const response = await postData("/api/company/create", companyObj, idToken);
        if (response.status === 200 || response.status === 201) {
            getAccountInfo();
            Toaster.show(getMessage("successMessages", "companyCreated", messages), 'success');
        } else {
            Toaster.show(getMessage("errorMessages", "createCompanyFailed", messages), 'error');
            console.error('Create company failed');
        }
        setLoading(false);
    }

    const handleCompanyUpdate = async () => {
        if (!validate()) {
            return;
        }
        setLoading(true);
        const idToken = user ? user!.idToken : null;

        const companyObj = {
            name: company,
            nif: nipc,
        }
        const response = await putData("/api/company/update", companyObj, idToken);
        if (response.status === 200 || response.status === 201) {
            clearCache("/accountInfo");
            getAccountInfo();
            Toaster.show(getMessage("successMessages", "companyUpdated", messages), 'success');
        } else {
            Toaster.show(getMessage("errorMessages", "updateCompanyFailed", messages), 'error');
            console.error('Update company failed');
        }
        setLoading(false);
    }

    const handlePhoneUpdate = async () => {
        //VALIDATE PHONE NUMBER
        setLoading(true);
        const idToken = user ? user!.idToken : null;

        const response = await putData("/api/user/updatePhone", { phoneNumber: phone }, idToken);
        const userData = {
            ...user,
            phone: phone,
            email: user!.email,
            name: user!.name
        };

        if (response.status === 200 || response.status === 201) {
            Toaster.show(getMessage("successMessages", "contactUpdated", messages), 'success');
            setUser(userData);
        } else {
            Toaster.show(getMessage("errorMessages", "updatePhoneFailed", messages), 'error');
            console.error('Update phone failed');
        }

        setLoading(false);
    }


    const validate = () => {
        if (company === '' || nipc === '') {
            Toaster.show(getMessage("errorMessages", "validateEmptyFields", messages), 'error');
            return false;
        }
        if (isNaN(Number(nipc))) {
            Toaster.show(getMessage("errorMessages", "validateNipcNumber", messages), 'error');
            return false;
        }
        if (nipc.length !== 9) {
            Toaster.show(getMessage("errorMessages", "validateNipcLength", messages), 'error');
            return false;
        }
        return true;
    }

    const formatDate = (date: string) => {
        return format(new Date(date), 'yy/MM/dd HH:mm:ss');
    }

    const statusChange = (index: number) => {
        const updatedEmployees = employees.map((employee, i) => {
            if (i === index) {
                return { ...employee, status: !employee.status };
            }
            return employee;
        });

        setemployees(updatedEmployees);
        change_status(updatedEmployees[index]);
    }

    const change_status = async (item: TeamMember) => {
        const idToken = user ? user.idToken : null;

        const response = await postData("/api/user/toggleUser", { email: item.email }, idToken);
        if (response.status === 200 || response.status === 201) {
            const updatedEmployees = employees.map((elem) => {
                if (elem.email === item.email) {
                    return { ...elem, status: !elem.status };
                }
                return elem;
            });

            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key && key.startsWith('team') && key !== 'teams') {
                    localStorage.removeItem(key);
                }
            }
            setemployees(updatedEmployees);

        } else {
            Toaster.show(getMessage("errorMessages", "changeUserStatusFailed", messages), 'error');
            console.error('Change order_item_status failed');
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target?.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json<{ name: string, email: string }>(sheet, {
                    header: ['name', 'email'],
                });
                setData(jsonData);
            };
            reader.readAsBinaryString(file);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Reset the input value
            }
        }
    };

    // Add or remove (if already is selected) member from selectedMembers
    const handleAddMember = (username: string) => {
        if (selectedMembers.includes(username)) {
            setSelectedMembers(selectedMembers.filter((elem) => elem !== username));
        } else {
            setSelectedMembers([...selectedMembers, username]);
        }
    };

    // Open file input
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Close modal and reset selected members
    const toggleCancel = () => {
        setShowModal(false);
        setSelectedMembers([]);
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Send invite email to selected members
    const handleAdd = async () => {
        if (selectedMembers.length === 0 && newUserEmail === '') {
            Toaster.show(getMessage("errorMessages", "selectOrInsertMember", messages), 'error');
            return;
        }

        let emailsToSend = selectedMembers;

        if (newUserEmail !== '' && isValidEmail(newUserEmail)) {
            emailsToSend = [...emailsToSend, newUserEmail];
        }

        setLoading(true);

        for (const member of emailsToSend) {
            const dataEncrypted = encryptData(company_id.toString());

            const mainUrl = process.env.REACT_APP_DEPLOY_URL || window.location.href.replace('/settings', '');
            const url = `${mainUrl}?param1=${dataEncrypted}&param2=${member}`;

            const htmlContent = template.replace('<a id="confirmLink" href="#">', `<a id="confirmLink" href=${url}>`);
            if (member) {
                const templateParams = {
                    to_email: member,
                    subject: `Welcome to Company ${company}`,
                    my_HTML: htmlContent
                };
                try {
                    await emailjs.send(
                        process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
                        process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '',
                        templateParams,
                        process.env.REACT_APP_EMAILJS_USER_ID || ""
                    );
                    Toaster.show(getMessage("successMessages", "emailSent", messages), 'success');
                    setShowModal(false);
                    setData([]);
                    setSelectedMembers([]);
                    setNewUserEmail('');
                } catch (error) {
                    Toaster.show(getMessage("errorMessages", "failedToSendEmail", messages), 'error');
                    console.error('Failed to send email', error);
                }
            }
        }

        setLoading(false);
    };

    // Change role of a user {item.mail}
    const changeRole = async (item: TeamMember) => {
        const idToken = user ? user!.idToken : null;
        const response = await postData("/api/roles/setRole",
            {
                userMail: item.email,
                role: selectedRole
            }
            , idToken);
        if (response.status === 200 || response.status === 201) {
            const updatedEmployees = employees.map((elem) => {
                if (elem.email === item.email) {
                    return { ...elem, role: selectedRole };
                }
                return elem;
            });

            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key && key.startsWith('team') && key !== 'teams') {
                    localStorage.removeItem(key);
                }
            }
            setemployees(updatedEmployees);
            Toaster.show(getMessage("successMessages", "userRoleChanged", messages), 'success');
        } else {
            Toaster.show(getMessage("errorMessages", "changeRoleFailed", messages), 'error');
            console.error('Change role failed');
        }

    }

    // Toggle edit row
    const toggleEditRow = (index: number) => {
        if (editRowVisible === index) {
            setEditRowVisible(-1);
            setSelectedRole('n/a');
        } else {
            setEditRowVisible(index);
        }

    };

    // Handle cancel role change
    const handleCancel = () => {
        toggleEditRow(-1);
        setSelectedRole('n/a');
    }

    // Handle role change
    const handleSelectedRole = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRole(event.target.value);
    };

    // Fetch data on page load
    useEffect(() => {
        getAccountInfo();
    }, [getAccountInfo]);

    useEffect(() => {
        setPhone(user?.phone || '');
    }, [user]);



    return (
        <Page >

            <div className='h-full '>
                <div className='flex'>
                    <h2 className='text-xl'>{getMessage("other", "settings", messages)}</h2>
                </div>

                <div className='grid miledois:grid-cols-4 gap-8 pt-8'>
                    <div className='col-span-3 milesete:col-span-2'>

                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col border-2 p-4 rounded-xl'>
                                <div className='flex mil:justify-between w-full gap-20 mil:gap-4'>
                                    <p className='font-bold text-xl'>{getMessage("other", "company", messages)}</p>
                                    {!hasCompany ?
                                        <button onClick={handleCompanyCreate} className='px-8 py-2 bg-dashBlue text-white rounded-full '>
                                            {getMessage("buttonNames", "save", messages)}
                                        </button>
                                        :
                                        <button onClick={handleCompanyUpdate} className='px-8 py-2 bg-dashBlue text-white rounded-full '>
                                            {getMessage("buttonNames", "edit", messages)}
                                        </button>}
                                </div>
                                <div className='flex flex-col mil:flex-row gap-8 mil:gap-0 justify-between py-4 mil:px-4 '>
                                    <div className='flex flex-col gap-4'>
                                        <div className='flex justify-between'>
                                            <p className='font-bold text-lg'>{getMessage("other", "companyName", messages)}</p>
                                        </div>
                                        <input type='text' value={company} placeholder={getMessage("other", "companyName", messages)} onChange={(e) => setCompany(e.target.value)} className='w-64 cinco:w-72 p-2 rounded-2xl outline-none bg-zinc-200  px-6' />
                                    </div>
                                    <div className='flex flex-col gap-4'>
                                        <p className='font-bold text-lg'>{getMessage("other", "companyNipc", messages)}</p>
                                        <input type='text' value={nipc} placeholder={getMessage("other", "companyNipc", messages)} onChange={(e) => setNipc(e.target.value)} maxLength={9} className='w-64 cinco:w-72 p-2 rounded-2xl outline-none bg-zinc-200 px-6' />
                                    </div>
                                </div>
                            </div>
                            <div className='border-2 rounded-xl p-4'>
                                <p className='font-bold text-xl'>{getMessage("other", "user", messages)}</p>
                                <div className='flex gap-6 flex-col mil:flex-row py-6 justify-between mil:px-4'>
                                    <div className='flex flex-col gap-4'>
                                        <p className='font-bold text-lg'>{getMessage("other", "userEmail", messages)}</p>
                                        <input type='text' disabled value={email} placeholder={getMessage("other", "userEmail", messages)} onChange={(e) => setEmail(e.target.value)} className='w-64 cinco:w-72 p-2 rounded-2xl outline-none bg-zinc-200  px-6' />
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <div className='flex mil:justify-between items-center gap-20 mil:gap-0'>
                                            <p className='font-bold text-lg'>{getMessage("other", "userContact", messages)}</p>
                                            {!user?.phone ?
                                                <div >
                                                    {<IoAddCircle className='cursor-pointer' onClick={handlePhoneUpdate} size={32} color='#484c9c' title={getMessage("buttonNames", "addPhone", messages)} />}
                                                </div>
                                                :
                                                <div >
                                                    {<RiEdit2Fill className='cursor-pointer' onClick={handlePhoneUpdate} size={32} color='#484c9c' title={getMessage("buttonNames", "editPhone", messages)} />}
                                                </div>}
                                        </div>
                                        <div className='w-64 cinco:w-72 p-1 px-6 rounded-2xl outline-none bg-zinc-200 flex justify-center' >
                                            <PhoneInput
                                                contact={phone || ''}
                                                setContact={setPhone}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-3 miledois:col-span-1 miledois:pl-0 milesete:col-span-2'>
                        <div className='pb-4'>
                            <p className='font-bold text-xl h-12 flex items-center justify-center'>{getMessage("other", "notifications", messages)}</p>
                        </div>
                        <div className=' border-2 border-solid rounded-2xl p-4 pr-2'>
                            <div className='scroll overflow-x-auto h-60 flex items-center justify-center'>
                                <p>{getMessage("other", "noNotifications", messages)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-12 flex justify-between flex-wrap gap-4'>
                    <p className='text-xl flex items-center'>{getMessage("other", "totalUsers", messages)} {employees?.length ? employees!.length : 0}</p>
                    <p className='p-2 bg-zinc-200 rounded-2xl text-center w-32 text-dashBlue'>{teamCount} {getMessage("other", "teams", messages)}</p>
                </div>
                {employees && hasCompany &&
                    <div className='pb-20'>
                        <div className='flex flex-col gap-4'>
                            <div className='flex justify-between items-center gap-4 flex-wrap'>
                                <p className='font-bold text-xl'>{getMessage("other", "companyUsers", messages)}</p>
                                <button onClick={() => setShowModal(true)} className="bg-dashBlue w-60 text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                                    <span>{getMessage("buttonNames", "addMember", messages)}</span>
                                </button>
                            </div>
                            <div className='relative'>
                                <div className='scroll overflow-x-auto max-h-96 h-96 pr-2'>
                                    <table className="min-w-full">
                                        <thead>
                                            <tr className='text-sm text-left text-zinc-500'>
                                                {[getMessage("other", "name", messages),
                                                    "Email",
                                                getMessage("other", "creationDate", messages),
                                                getMessage("other", "status", messages),
                                                getMessage("other", "actions", messages)].map((header) => (
                                                    <th key={header} className="px-4 cinco:px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                                        {header}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className='overflow-y-auto '>
                                            {employees.map((elem, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className='text-zinc-500 border-t-2 border-zinc-200'>
                                                        <td className="px-4 cinco:px-6 py-10">
                                                            <div className="text-sm font-bold text-black rounded-2xl">{elem.name}</div>
                                                        </td>
                                                        <td className="px-4 cinco:px-6 py-10">
                                                            <div className="text-sm rounded-2xl">{elem.email}</div>
                                                        </td>
                                                        <td className="px-4 cinco:px-6 py-10">
                                                            <div className="text-sm rounded-2xl">{formatDate(elem.created_at)}</div>
                                                        </td>
                                                        <td className="px-4 cinco:px-6 py-10">
                                                            <Toggle
                                                                defaultChecked={elem.status}
                                                                onChange={() => statusChange(index)}
                                                            />
                                                        </td>
                                                        <td className="px-4 cinco:px-6 py-10 text-sm font-medium">
                                                            <button onClick={() => toggleEditRow(index)}
                                                                className="text-sm font-bold text-indigo-600 hover:text-indigo-900">
                                                                {getMessage("buttonNames", "edit", messages)}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    {editRowVisible === index && (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className="flex flex-col gap-8 pb-10 px-6 w-full">
                                                                    <div className='flex gap-4 items-center w-full'>
                                                                        <label>{getMessage("other", "role", messages)}</label>
                                                                        <select
                                                                            className="w-64 cinco:w-72 outline-none px-4 py-2 text-sm border bg-transparent border-gray-300 border-solid rounded-lg"
                                                                            value={selectedRole === 'n/a' ? elem.role : selectedRole}
                                                                            onChange={handleSelectedRole}
                                                                        >
                                                                            <option className='text-zinc-500' value="n/a">{getMessage("other", "selectARole", messages)}</option>
                                                                            {roles.map((role, index) => (
                                                                                <option key={index} value={role}>{role}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className='flex justify-end gap-8'>
                                                                        <button onClick={handleCancel} className='bg-zinc-200 text-black py-2 px-4 rounded-lg'>
                                                                            {getMessage("buttonNames", "cancel", messages)}
                                                                        </button>
                                                                        <button onClick={() => changeRole(elem)} className='bg-dashBlue text-white py-2 px-4 rounded-lg'>
                                                                            {getMessage("buttonNames", "change", messages)}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            <tr className='sticky bg-white w-full bottom-0 text-zinc-500 border-t-2 border-zinc-200'>
                                                <td className="px-4 cinco:px-6 py-10">
                                                    <div className="text-sm rounded-2xl">Email</div>
                                                </td>
                                                <td className="px-4 cinco:px-6 py-10">
                                                    <input
                                                        value={newUserEmail}
                                                        onChange={(e) => setNewUserEmail(e.target.value)}
                                                        placeholder='| Email'
                                                        className="text-sm font-bold text-black px-4 py-2"
                                                    />
                                                </td>
                                                <td className="px-4 cinco:px-6 py-10">
                                                    <div className="text-sm rounded-2xl">{formatDate(today.toISOString())}</div>
                                                </td>
                                                <td className="px-4 cinco:px-6 py-10">
                                                    <Toggle
                                                        disabled
                                                        defaultChecked={true}
                                                    />
                                                </td>
                                                <td className="px-4 cinco:px-6 py-10 text-sm font-medium">
                                                    <button onClick={handleAdd} className="text-sm font-bold text-indigo-600 hover:text-indigo-900">
                                                        {getMessage("buttonNames", "add", messages)}
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
            <Modal isOpen={showModal} handler={handleAdd}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{getMessage("other", "addMember", messages)}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={getMessage("buttonNames", "close", messages)} />
                    </div>
                    <div className='flex flex-col gap-4 py-4'>
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="fileInput"
                            ref={fileInputRef}
                        />
                        <button onClick={handleButtonClick} className='w-52 p-3 bg-dashBlue text-white rounded-full text-lg'>
                            <div className='flex gap-4 justify-center'>
                                <p>{getMessage("buttonNames", "import", messages)}</p>
                                <LuImport size={28} title={getMessage("buttonNames", "import", messages)} />
                            </div>
                        </button>
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={32} className='hidden oito:block' />
                            <div className='flex flex-col gap-1'>
                                <p className='text-sm'>{getMessage("other", "importExcelFile", messages)}
                                </p>
                                <p className='text-sm'>{getMessage("other", "excelFileFormat", messages)}
                                </p>
                            </div>
                        </div>
                    </div>
                    {data.length > 0 && <div className='scroll overflow-x-auto max-h-96 pr-2'>

                        <table className="min-w-full">
                            <thead className="">
                                <tr className='text-sm text-left text-zinc-500 '>
                                    {[getMessage("other", "name", messages),
                                        "Email",
                                    getMessage("buttonNames", "select", messages)].map((header) => (
                                        <th key={header} className="px-4 cinco:px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="">
                                {data.map((elem, index) => (
                                    <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                        <td className="px-6 py-10">
                                            <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                        </td>
                                        <td className="px-6 py-10  ">
                                            <div className="text-sm rounded-2xl ">{elem.email}</div>
                                        </td>
                                        <td className="px-6 py-10 text-sm font-medium flex justify-center">
                                            <div
                                                className={`cursor-pointer border flex items-center justify-center border-black rounded-lg w-6 aspect-square ${selectedMembers.includes(elem.email) ? 'bg-blue-600 ' : ''}`}
                                                onClick={() => handleAddMember(elem.email)}
                                            >
                                                <FaCheck className='text-white' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    <div className='flex gap-8 justify-end pt-8'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{getMessage("buttonNames", "cancel", messages)}</span>
                        </button>
                        <button onClick={handleAdd} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{getMessage("buttonNames", "add", messages)}</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </Page>

    );
}

