import React from 'react';
import BillingNavbar from './billingNavbar';
import Page from '../Page';
import { BsExclamationCircle } from "react-icons/bs";
import { getMessage } from '../../multilanguageSupport/mutilanguage';
import messages from '../../multilanguageSupport/messages/billing.json';
export default function Subscriptions() {


    return (
        <Page>
            <BillingNavbar>
                <div className='flex flex-col gap-4'>
                <div className='w-full justify-end flex'>
                    <button className='bg-blue-300 hover:bg-blue-400 transition-all ease-in-out duration-300 text-white font-bold py-2 px-4 rounded-full flex justify-center items-center gap-2'>
                        <BsExclamationCircle color='#383b79' size={24} title={getMessage("other","Billing Support", messages )} />
                        <p className='text-dashBlueDark font-semibold'>{getMessage("other","Billing Support", messages )}</p>
                    </button>
                </div>
                <div className='w-full border-2 border-zinc-200 rounded-lg p-8 f'>
                    <p className='text-xl font-bold'>{getMessage("other","Subscriptions", messages )}</p>
                    <p className='py-4'>{getMessage("other","SubscriptionsText", messages )}</p>
                    <p className='pt-4'>{getMessage("other","SubscriptionsSubText", messages )}</p>
                </div>
                </div>
            </BillingNavbar>
        </Page>

    );
}

