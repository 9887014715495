import React from 'react';
import { getMessage } from '../multilanguageSupport/mutilanguage';
import messages from '../multilanguageSupport/messages/notFound.json';
export default function NotFound() {
    return (
        <div className='w-full min-h-screen flex flex-col justify-center items-center text-black bg-[var(--darkGray)]'>
            <p className='absolute top-8 left-8 text-[1.2rem] cursor-pointer' onClick={() => window.history.back()}>🡐&ensp;{getMessage("other", "back", messages)}</p>
            <h1 className='text-[3.5rem]'>{getMessage("other", "error", messages)}</h1>
            <p className='text-[1.2rem]'>{getMessage("other", "notFound", messages)}</p>
        </div>
    );
}

