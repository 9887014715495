import React, { useState } from 'react';
import Loading from '../../components/Loading/loading';
import "../../components/customScroll.styles.scss";
import { getMessage } from '../../multilanguageSupport/mutilanguage';
import messages from '../../multilanguageSupport/messages/moduleMarketPlace.json';

export default function ModuleMarketPlace() {
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('inSite/');
    const arrayWith20Elements = new Array(20).fill(null);
    // const [templates, setTemplates] = useState<any []>([]);


    // useEffect(() => {
    //     setLoading(true)
    //     const  storedModuleTemplates = localStorage.getItem(`${query}`);
    //     if ( storedModuleTemplates) {
    //         const templates = JSON.parse( storedModuleTemplates);
    //         setTemplates(templates);
    //     } else {
    //         getModuleTemplates()
    //     }
    //     setLoading(false)
    // }, [query]);

    // const getModuleTemplates = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_API_URL}/templates/query`, {
    //             headers: {
    //                 'Authorization': `${user!.idToken}`
    //             }
    //         });
    //         setTemplates(response.data);
    //         localStorage.setItem(`${query}`, JSON.stringify(response.data))
    //     } catch (error) {
    //         console.error('Error getting module:', error);
    //     } finally {
    //         setLoading(false)
    //     }
    // }


    const Option = ({ name, link }: { name: string, link: string }) => {
        return (
            <button
                className={`text-lg text-left ${query.includes(name) ? "text-black" : "text-zinc-400"}`}
                onClick={() => setQuery(`${link}/${name}`)}
            >
                {name}
            </button>
        );
    };

    return (
        <div>
            <div className='h-full flex-col miledois:flex-row flex gap-10 pt-10'>
                <div className='w-full miledois:w-[20rem]'>
                    <p className='text-xl pb-6'>{getMessage("other", "categories", messages)}</p>
                    <div className='flex flex-row miledois:flex-col justify-around gap-8 pl-4'>
                        <div className='flex flex-col gap-4 justify-start'>
                            <button onClick={() => setQuery('inSite/')} className='flex flex-wrap gap-2 text-xl'>
                                <p>{getMessage("other", "templatesInSite", messages)}</p>
                                {/* <p className='text-indigo-400'>In-Site</p> */}
                            </button>
                            {/* TODO change to use getMessage when finished */}
                            <Option link='inSite/' name='Email trigger' /> 
                            <Option link='inSite/' name='Form info' />
                            <Option link='inSite/' name='Game' />
                            <Option link='inSite/' name='Discount' />
                            <Option link='inSite/' name='Design way' />
                        </div>
                        <div className='flex flex-col gap-4 justify-start'>
                            <button onClick={() => setQuery('campains/')} className='flex gap-2 text-xl'>
                                <p>{getMessage("other", "templatesCampains", messages)}</p>
                            </button>
                            <Option link='campains/' name='Comunicação' />
                            <Option link='campains/' name='DevTools' />
                            <Option link='campains/' name='Educação' />
                            <Option link='campains/' name='Documentação' />
                            <Option link='campains/' name='Blockchain' />
                        </div>
                    </div>
                </div>
                <div className='overflow-y-auto scroll max-h-[40rem] w-full'>
                    <div className='flex flex-wrap gap-10 items-center justify-center'>
                        {arrayWith20Elements.map((_, i) => (
                            <div key={i} className='flex flex-col gap-4 p-4 w-64 h-72 bg-zinc-100 rounded-2xl'>
                                {getMessage("other", "template", messages)} {i}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Loading isOpen={loading} />
        </div>

    );
}

