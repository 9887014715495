import React, { useState } from 'react';
import BillingNavbar from './billingNavbar';
import Page from '../Page';
import { getMessage } from '../../multilanguageSupport/mutilanguage';
import messages from '../../multilanguageSupport/messages/billing.json';

export default function Payments() {
    const [email, setEmail] = useState("email@example.com");
    const [emailNotification, setEmailNotification] = useState(true);
    const [name, setName] = useState("Duarte Barreiros Ferreira");
    const [street, setStreet] = useState("Rua Xavier de Araujo");
    const [countryCode, setCountryCode] = useState("Lisboa, Lisboa 1600-209");
    const [country, setCountry] = useState("Portugal");

    const [primary, setPrimary] = useState("Credit card");
    const [ending, setEnding] = useState("3902");
    const [expires, setExpires] = useState("03/26");

    const [secondary, setSecondary] = useState("Credit card");
    const [endingSecondary, setEndingSecondary] = useState("4873");
    const [expiresSecondary, setExpiresSecondary] = useState("04/26");
    return (
        <Page>
            <BillingNavbar>
                <div className='flex flex-col gap-10 w-full'>
                    <div className='border-2 border-zinc-200 rounded-lg mil:flex gap-2 w-full'>
                        <div className=' p-8  mil:w-[70%]'>
                            <p className='text-xl font-bold'>{getMessage("other","Payment methods", messages )}</p>
                            <p className=' text-zinc-500 pt-4'>{getMessage("other","monthly charges", messages )}</p>
                            <div className='grid grid-cols-2 gap-10 pt-8'>
                                <div>
                                    <p className='text-zinc-500 font-bold pb-2'>{getMessage("other","Primary", messages )}</p>
                                    <p className='text-zinc-500'>{primary}</p>
                                    <p className='text-zinc-500'>{getMessage("other","Ending in", messages )} {ending}</p>
                                    <p className='text-zinc-500'>{getMessage("other","Expires on", messages )} {expires}</p>
                                </div>
                                <div>
                                    <p className='text-zinc-500 font-bold pb-2'>{getMessage("other","BackUp", messages )}</p>
                                    <p className='text-zinc-500'>{secondary}</p>
                                    <p className='text-zinc-500'>{getMessage("other","Ending in", messages )} {endingSecondary}</p>
                                    <p className='text-zinc-500'>{getMessage("other","Expires on", messages )} {expiresSecondary}</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-zinc-100 mil:w-[30%] p-4 border-t-2 mil:border-t-0 mil:border-l-2 border-zinc-200 rounded-lg flex justify-center items-center'>
                            <button className='bg-indigo-400 text-white py-2 px-6 rounded-lg w-32'>{getMessage("other","Manage", messages )}</button>
                        </div>
                    </div>
                    <div className='border-2 border-zinc-200 rounded-lg mil:flex gap-2 w-full'>
                        <div className='flex flex-col gap-4 p-8  mil:w-[70%]'>
                            <p className='text-xl font-bold'>{getMessage("other","Billing address", messages )}</p>
                            <div className='italic flex flex-col gap-2'>
                                <p className=' text-zinc-500'>{name}</p>
                                <p className=' text-zinc-500'>{street}</p>
                                <p className=' text-zinc-500'>{countryCode}</p>
                                <p className=' text-zinc-500'>{country}</p>
                            </div>
                        </div>
                        <div className='bg-zinc-100 mil:w-[30%] p-4 border-t-2 mil:border-t-0 mil:border-l-2 border-zinc-200 rounded-lg flex justify-center items-center'>
                            <button className='bg-indigo-400 text-white py-2 px-6 rounded-lg w-32'>{getMessage("buttonNames","edit", messages )}</button>
                        </div>
                    </div>
                    <div className='w-full flex gap-2'>
                        <p className='text-zinc-500'>{getMessage("other","tax exempt", messages )}</p>
                        <a href='' className='underline text-indigo-400'>{getMessage("other","Cloudflare", messages )}</a>
                    </div>
                </div>
            </BillingNavbar>
        </Page>

    );
}

