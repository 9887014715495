
import { useState } from 'react';
import { useUser } from '../contexts/userContext';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Step 1: Import useLocation
import { IoSettingsOutline } from 'react-icons/io5';
import { HiOutlineLogout } from "@react-icons/all-files/hi/HiOutlineLogout";



const ProfileComponent = () => {
    const { user, setUser } = useUser();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    let letter = user?.email ? user.email[0].toUpperCase() : '';
    const location = useLocation();
    const imgSrc = user?.image ? user.image : null;
    const navigate = useNavigate();
    const lang = navigator.language || 'en';
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    function logOut() {
        setUser(null);
        localStorage.clear();
        navigate('/')
    }

    return (
        <div className='flex gap-14 justify-end'>
            <div className='flex items-center justify-end'>
                <div className='flex gap-6'>
                    <p>{location.pathname !== "/settings" ? "PUBLICADOR:" : "LOGGED AS:"}</p>
                    <p className='font-bold text-end'>{user?.email}</p>
                </div>
            </div>
            <div id='dropDown' className="relative">
                <div onClick={toggleDropdown} className="cursor-pointer">
                    {imgSrc ? (
                        <img
                            className="w-10 aspect-square border-2 border-black rounded-full bg-[var(--primary)]"
                            src={imgSrc}
                            alt="profileImage"
                        />
                    ) : (
                        <div className='relative bg-black rounded-full w-8 h-8 flex justify-center items-center'>
                            <p className='flex items-center justify-center absolute text-xl text-white top-[50%] translate-y-[-42%]'>{letter}</p>
                        </div>
                    )}
                </div>
                <div
                    className={`absolute text-zinc-500 right-0 mt-2 w-44 bg-white rounded-md shadow-lg z-10 overflow-hidden transition-all duration-500 ease-in-out ${isDropdownOpen ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0'
                        }`}
                >
                    <div className="p-3 flex flex-col gap-2">
                        <Link to={"/settings"} className='flex gap-2 p-2 items-center  justify-center border-b'>
                            <IoSettingsOutline size={20} title="Settings" />
                            <p>{lang === "pt" ? "Conta" : "Settings"}</p>
                        </Link>
                        <button
                            onClick={logOut}
                            className={`flex gap-2 p-2 items-center  justify-center border-b w-full`}
                        >
                            <HiOutlineLogout size={20} />
                            <p >{lang === "pt" ? "Sair" : "Logout"}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};




const Header = () => {

    const HeaderTop = () => {
        return (
            <div className='flex-col bg-zinc-100 hidden oito:flex h-20 w-full'>
                <div className='w-full flex justify-end seis:px-[4vw] px-[8vw] items-center h-[6rem]'>
                    <div className="flex items-center gap-8">
                        <div >
                            <ProfileComponent />
                        </div>
                    </div>
                </div>
            </div>

        )
    }



    return (
        <HeaderTop />
    );
}

export default Header;
