import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoIosStar } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from 'react-icons/io';
import ModuleMarketPlace from './ModuleMarketPlace';
import ModuleInfo from './ModuleInfo';
import Page from '../Page';
import { useUser } from '../../contexts/userContext';
import { clearCache, getCache, postData, setCache } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';
import { getMessage } from '../../multilanguageSupport/mutilanguage';
import messages from '../../multilanguageSupport/messages/modulePage.json'; // Import your JSON file

export default function ModulePage() {
    const { user } = useUser();
    const location = useLocation();
    const [numUsers, setNumUsers] = useState(10000000);
    const [selected, setSelected] = useState(true);
    const [selectedImage, setSelectedImage] = useState(0);
    const moduleName = location.pathname.replace('/marketplace/', '');

    // const [module, setModule] = useState<Module>();
    const { id, name, reviews, number_reviews, desc, images } = location.state || getCache(`/api/modules/${moduleName}`);
    setCache(`/api/modules/${name}`, { id, name, reviews, number_reviews, desc, images });

    // useEffect(() => {
    //     const getModule = async () => {
    //         const idToken = user ? user!.idToken : null;

    //         const response = await fetchData(`/api/modules/${id}`, idToken);
    //         if (response.status === 200 || response.status === 201) {
    //             console.log(response.data);
    //             // setModules(response.data);
    //         }
    //         else if (response.status === 204 || response.status === 404) {
    //             Toaster.show('Não foi possível obter o módulo.', 'error');
    //         }
    //         else {
    //             Toaster.show('Não foi possível obter o módulo.', 'error');
    //             console.error('Get module market failed');
    //         }
    //         setLoading(false);

    //     }
    //     getModule();
    // }, [id, user]);

    const addToCart = async () => {

        const idToken = user ? user!.idToken : null;

        const featureIds = {
            featureIds: [id]
        };
        const response = await postData(`/api/order/additems`, featureIds, idToken);
        if (response.status === 200 || response.status === 201) {
            console.log(response.data);
            clearCache("/api/order/cart");
            Toaster.show(getMessage("successMessages", "addedToCart", messages), 'success');
        } else {
            Toaster.show(getMessage("errorMessages", "addedFailed", messages), 'error');
            console.error('add to cart failed');
        }
    }


    function inRange(num: number, max: number, min: number): boolean {
        return num >= min && num <= max;
    }
    function roundness(num: number, selected: number): string {
        if (num === selected) return 'rounded-xl';
        if (num >= selected - 2 && num < selected) return 'rounded-l-xl pr-2';
        if (num <= selected + 2 && num > selected) return 'rounded-r-xl pl-2';
        else return '';
    }

    return (
        <Page >
            <div className='h-full min-h-screen'>
                <div className='flex justify-between'>
                    <Link to="/marketplace" className='flex gap-2 items-center w-fit'>
                        <IoArrowBack size={32} title={getMessage("other", "goToHome", messages)} />
                        <p className='text-xl'>{getMessage("other", "goToHome", messages)}</p>
                    </Link>
                    <button onClick={addToCart} className="bg-dashBlue w-fit text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <span>{getMessage("buttonNames", "addToCart", messages)}</span>
                    </button>
                </div>
                <div className='py-6 flex flex-col gap-8'>
                    <div className='flex gap-20 relative select-none'>
                        {images && images.length > 0 &&
                            <>
                                <div className='flex '>
                                    {images.map((img: string, i: number) => (
                                        <img onClick={() => setSelectedImage(i)} key={i} src={img} alt={name} className={`${selectedImage === i ? "w-60 seis:w-72 miledois:w-96" : inRange(i, selectedImage + 2, selectedImage - 2) ? "w-8 seis:w-12 miledois:w-20" : "w-0"} ${roundness(i, selectedImage)} transition-all duration-700 ease-in-out h-52 seis:h-64 miledois:h-72 object-cover cursor-pointer`} />
                                    ))}
                                </div>
                                <div className={`hidden miledois:flex flex-col milequatro:flex-row gap-4 items-center text-zinc-400 absolute top-[50%] translate-y-[-50%] left-[45rem] milequatro:left-[50rem]`}>
                                    <div className='rounded-full border-4 border-zinc-400 flex items-center justify-center'>
                                        <IoIosArrowBack onClick={() => setSelectedImage(selectedImage === 0 ? images.length - 1 : selectedImage - 1)}
                                            size={45} className='cursor-pointer' />
                                    </div>
                                    <div className='rounded-full border-4 border-zinc-400 flex items-center justify-center'>
                                        <IoIosArrowForward onClick={() => setSelectedImage(selectedImage === images.length - 1 ? 0 : selectedImage + 1)}
                                            size={45} className='cursor-pointer' />
                                    </div>
                                </div>
                            </>}
                    </div>
                    <div className='flex justify-between'>
                        <div className='flex flex-col gap-4'>
                            <p className='text-xl font-bold'>{name}</p>
                            <div className='flex flex-col seis:flex-row gap-8'>
                                <div className='flex gap-2 items-center'>
                                    <p className='flex items-center h-full text-lg pt-1'>{reviews}</p>
                                    <IoIosStar size={20} title={getMessage("other", "reviews", messages)} />
                                    <p className='text-zinc-400'>({number_reviews || "0"})</p>
                                </div>
                                <div className='flex gap-3 text-zinc-400 items-center'>
                                    <p>{numUsers}</p>
                                    <p >{getMessage("other", "users", messages)}</p>
                                </div>
                            </div>
                            <p>{desc}</p>
                        </div>
                        {/* <div>
                            <button className='px-4 py-2 border-2 flex gap-2 rounded-full w-22 border-zinc-500 text-zinc-500'>
                                <p>Add</p>
                                <p>+</p>
                            </button>
                        </div> */}
                    </div>
                    <div className="py-4">
                        <hr className="border-zinc-200" />
                    </div>
                </div>
                <div className='flex gap-2 text-xl'>
                    <button onClick={() => setSelected(true)} className={`${selected ? "text-black" : "text-zinc-400"} transition-all duration-500 ease-in-out`}>{getMessage("buttonNames", "infoDetails", messages)}</button>
                    <p>|</p>
                    <button onClick={() => setSelected(false)} className={`${!selected ? "text-black" : "text-zinc-400"} transition-all duration-500 ease-in-out`}>{getMessage("buttonNames", "marketplace", messages)}</button>
                </div>
                <div className={`${selected ? "opacity-100" : "opacity-0"} transition-all ease-in-out duration-1000`}>
                    {selected && <ModuleInfo module={{
                        id: id,
                        name: name,
                        reviews: reviews,
                        reviews_number: number_reviews,
                        description: desc,
                    }} />}
                </div>
                <div className={`${!selected ? "opacity-100" : "opacity-0"} transition-all ease-in-out duration-1000`}>
                    {!selected && <ModuleMarketPlace />}
                </div>
            </div>
        </Page>

    );
}

