
type Messages = {
    [category: string]: {
        [lang: string]: {
            [key: string]: string;
        };
    };
};

export function getMessage(category: string, key: string, messages: Messages): string {

    const lang = navigator.language === "pt" ? "pt" : "en";

    if (messages[category] && messages[category][lang] && messages[category][lang][key]) {
        return messages[category][lang][key];
    }
    return key; // Return the key itself if the message is not found
}