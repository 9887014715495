import React, { ReactElement, useEffect, useState } from 'react';
import Page from './Page';
import { AiOutlineMessage, AiOutlineUser } from 'react-icons/ai';
import { AiOutlineContacts } from 'react-icons/ai';
import { AiOutlineTeam } from 'react-icons/ai';
import { AiOutlineTool } from 'react-icons/ai';
import { AiOutlineCreditCard } from 'react-icons/ai';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { getMessage } from '../multilanguageSupport/mutilanguage';
import messages from '../multilanguageSupport/messages/helpCenter.json';
export default function HelpCenter() {

    const [opacity, setOpacity] = useState(1);
    // const [showSupport, setShowSupport] = useState(false);
    // const [message, setMessage] = useState<string>('');
    // const [subject, setSubject] = useState<string>('');



    const HelpCard = ({ title, description, icon }: { title: string, description: string, icon: ReactElement }) => {
        return (
            <div className='w-full border-2 border-zinc-200 rounded-lg p-8 flex flex-col gap-2 cursor-pointer'>
                <div className='flex justify-center items-center pb-6'>
                    <div className='relative'>
                        <div className={`absolute right-[2px] bottom-[2px]`}>{icon}</div>
                        <div className=''>{React.cloneElement(icon, { color: "#f8bf59" })}</div>
                    </div>
                </div>
                <div className='flex flex-col gap-4 text-center'>
                    <p className='text-xl font-bold'>{title}</p>
                    <p className='text-zinc-500'>{description}</p>
                </div>
            </div>
        );
    }

    useEffect(() => {
        //make a fade effect on scroll down for the support button
        const handleScroll = () => {
            const maxScrollDepth = 500;
            const newOpacity = Math.max(0.3, 1 - (window.scrollY / maxScrollDepth) * 0.6);
            setOpacity(newOpacity);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <Page>
            <div className='h-full miledois:h-screen'>
                <div className='grid nove:grid-cols-2 miledois:grid-cols-3 gap-8'>
                    <HelpCard
                        title={getMessage("other", "App Usage", messages)}
                        description={getMessage("other", "Discover", messages)}
                        icon={<AiOutlineInfoCircle color='#383b79' size={100} title={getMessage("other", "App Usage", messages)} />} />
                    <HelpCard
                        title={getMessage("other", "Account", messages)}
                        description={getMessage("other", "Find answers", messages)}
                        icon={<AiOutlineUser color='#383b79' size={100} title={getMessage("other", "Account", messages)} />} />
                    <HelpCard
                        title={getMessage("other", "Billing", messages)}
                        description={getMessage("other", "Get help with payments", messages)}
                        icon={<AiOutlineCreditCard color='#383b79' size={100} title={getMessage("other", "Billing", messages)} />} />
                    <HelpCard
                        title={getMessage("other", "Module Management", messages)}
                        description={getMessage("other", "get new modules", messages)}
                        icon={<AiOutlineTool color='#383b79' size={100} title={getMessage("other", "Module Management", messages)} />} />
                    <HelpCard
                        title={getMessage("other", "Teams Managemente", messages)}
                        description={getMessage("other", "Manage teams", messages)}
                        icon={<AiOutlineTeam color='#383b79' size={100} title={getMessage("other", "Teams Managemente", messages)} />} />
                    <HelpCard
                        title={getMessage("other", "Contacts", messages)}
                        description={getMessage("other", "Contact us", messages)}
                        icon={<AiOutlineContacts color='#383b79' size={100} title={getMessage("other", "Contacts", messages)} />} />
                </div>
                <a href='mailto:geral@visiond.pt' style={{ opacity }} className='fixed bottom-20 right-10 bg-blue-500 hover:scale-105 transition-all ease-in-out duration-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'>
                    <div className='flex gap-2 items-center'>
                        <AiOutlineMessage size={32} title={getMessage("other", "chat", messages)} />
                        <p className='font-bold'>{getMessage("other", "support", messages)}</p>
                    </div>
                </a>
            </div>
            {/* <Modal isOpen={showSupport} handler={() => null}>
                <div className='w-full h-full p-8'>
                    <div className='flex justify-between gap-4 items-center'>
                        <p className='text-xl font-bold'>Chat with us</p>
                        <button onClick={() => setShowSupport(false)} >
                            <IoIosClose className='cursor-pointer' size={50} title={"Close"} />
                        </button>
                    </div>
                    <form action="mailto:someone@example.com" method="post" encType="text/plain" className='flex flex-col gap-4 pt-10'>
                        <div className='flex gap-2 flex-col'>
                            <div className='flex flex-col'>
                                <p>Subject</p>
                                <p className='text-sm text-zinc-400'>What is your question about?</p>
                            </div>
                            <input value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='| Subject' className='border-2 border-zinc-200 rounded-lg p-4 outline-none'></input>
                        </div>
                        <p >We are here to help you with any questions you may have. Please feel free to ask us anything.</p>
                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} className='border-2 border-zinc-200 rounded-lg p-4 h-52 outline-none'></textarea>
                        <div className='flex justify-center'>
                            <button type='submit' className='bg-blue-500 w-fit hover:bg-blue-700 transition-all ease-in-out duration-300 text-white font-bold py-2 px-6 rounded-full'>
                                <p className='font-bold'>Send</p>
                            </button>
                        </div>
                    </form>
                </div>
            </Modal> */}
        </Page>

    );
}

