import React, { useEffect, useState } from 'react';
import { IoHelpCircleSharp } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import "../components/customScroll.styles.scss";
import Modal from '../components/Modal';
import Page from './Page';
import { Tooltip, ResponsiveContainer, Cell, PieChart, Pie } from 'recharts';
import Loading from '../components/Loading/loading';
import { PiCube } from 'react-icons/pi';
import { BiRedo } from "react-icons/bi";
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import "../components/Toggle/toggle.styles.scss";
import { useUser } from '../contexts/userContext';
import { Module } from '../interfaces/Module';
import { clearCache, fetchData, postData, putData } from '../components/DataHandler';
import Toaster from '../components/Toaster';
import { COLORS } from '../content/colors';
import { ChartData } from '../interfaces/ChartData';
import { getMessage } from '../multilanguageSupport/mutilanguage';
import messages from '../multilanguageSupport/messages/home.json';

export default function Home() {
    const { user } = useUser();
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const [modules, setmodules] = useState<Module[]>([]);
    const [activeModules, setActiveModules] = useState(0);
    const [filteredModules, setfilteredModules] = useState<Module[]>([]);
    const [totalPrices, setTotalPrices] = useState(0);
    const [search, setSearch] = useState<string>('');
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedModules, setselectedModules] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [editRowVisible, setEditRowVisible] = useState(-1);
    const [company_id, setCompany_id] = useState(0);
    const [update, setUpdate] = useState<boolean>(false);


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };


    const handleAddMember = (username: string) => {
        if (selectedModules.includes(username)) {
            setselectedModules(selectedModules.filter((elem) => elem !== username));
        } else {
            setselectedModules([...selectedModules, username]);
        }
    };

    const toggleCancel = () => {
        setShowModal(false);
        setselectedModules([]);
    }

    const handleorder_item_statusChange = (index: number) => {
        const updatedEmployees = filteredModules.map((employee, i) => {
            if (i === index) {
                return { ...employee, order_item_status: !employee.order_item_status };
            }
            return employee;
        });

        setfilteredModules(updatedEmployees);
        changeorder_item_status(updatedEmployees[index]);
    }

    const changeorder_item_status = async (item: Module) => {
        const idToken = user ? user!.idToken : null;

        const response = await putData(`/api/features/toggleFeature/${item.id}`, {}, idToken);
        if (response.status === 200 || response.status === 201) {
            const updatedModules = modules.map((module) => {
                if (module.id === item.id) {
                    return { ...module, order_item_status: !module.order_item_status };
                }
                return module;
            });
            clearCache("/api/modules");
            setUpdate(!update);
            setmodules(updatedModules);
            setfilteredModules(updatedModules);
        } else {
            Toaster.show(getMessage("errorMessages", "item_status", messages), 'error');
            console.error('Change order_item_status failed');
        }
    }

    const toggleEditRow = (index: number) => {
        if (editRowVisible === index) {
            setEditRowVisible(-1);
        } else {
            setEditRowVisible(index);
        }

    };

    const handleCancel = () => {
        toggleEditRow(-1);
    }

    const GenerateApiKey = async (item: Module) => {
        const idToken = user ? user!.idToken : null;

        const response = await postData("/api/modules/generate", {
            featureId: item.id,
            companyId: company_id
        }, idToken);

        if (response.status === 200 || response.status === 201) {
            const updatedModules = modules.map((module) => {
                if (module.id === item.id) {
                    return { ...module, apiKey: response.data.apiKey };
                }
                return module;
            });
            clearCache("/api/modules");
            setmodules(updatedModules);
            setfilteredModules(updatedModules);
        } else {
            Toaster.show(getMessage("errorMessages", "key", messages), 'error');
            console.error('Generate apiKey failed');
        }
    }

    /*TESTING PORPOSES */
    // function generateRandomString(wordCount: number): string {
    //     const words = [
    //         "apple", "banana", "cherry", "date", "elephant", "fig", "grape", "honeydew",
    //         "iguana", "jackfruit", "kiwi", "lemon", "mango", "nectarine", "orange", "papaya",
    //         "quince", "raspberry", "strawberry", "tangerine", "umbrella", "vanilla", "watermelon",
    //         "xylophone", "yam", "zebra"
    //     ];

    //     let randomString = '';

    //     for (let i = 0; i < wordCount; i++) {
    //         const randomIndex = Math.floor(Math.random() * words.length);
    //         randomString += words[randomIndex] + (i < wordCount - 1 ? ' ' : '');
    //     }

    //     return randomString;
    // }

    // function generateRandomNumber(min: number = 100, max: number = 1000): number {
    //     return Math.floor(Math.random() * (max - min + 1)) + min;
    // }

    // const addModule = async () => {
    //     const idToken = user ? user!.idToken : null;

    //     const newModule = {
    //         name: generateRandomString(2),
    //         description: generateRandomString(5),
    //         price: generateRandomNumber(),
    //         type: 'module',
    //         status: true,
    //     };
    //     const response = await postData("/addModuleTest", newModule, idToken);
    //     if (response.status === 200 || response.status === 201) {
    //         const updatedModules = [...modules, { ...newModule, id: response.data.featureId, order_item_status: true, apiKey: "" }];
    //         clearCache("/api/modules/");
    //         setmodules(updatedModules);
    //         setfilteredModules(updatedModules);
    //     } else {
    //         Toaster.show(`Não foi possível adicionar o módulo. Tente novamente.`, 'error');
    //         console.error('Add module failed');
    //     }
    // }
    /*TESTING PORPOSES */


    useEffect(() => {
        const fetchModules = async () => {
            const idToken = user ? user!.idToken : null;

            setLoading(true);
            const response = await fetchData("/api/modules/", idToken);
            if (response.status === 200 || response.status === 201) {
                setmodules(response.data);
                setfilteredModules(response.data);
            } else {
                Toaster.show(getMessage("errorMessages", "getModules", messages), 'error');
                console.error('Get modules failed');
            }
            setLoading(false);
        }
        fetchModules();
    }, [user, update]);


    useEffect(() => {
        if (modules.length > 0) {
            let activeModules = 0 as number;
            let totalPrices = 0 as number;
            let chartData = [] as ChartData[];
            for (let i = 0; i < modules.length; i++) {
                if (modules[i].order_item_status) {
                    activeModules++;
                }
                totalPrices += Number(modules[i].price);
                chartData.push({ name: modules[i].name, value: Number(modules[i].price) });
            }
            setChartData(chartData);
            setActiveModules(activeModules);
            setTotalPrices(totalPrices);
        }
    }, [modules]);


    useEffect(() => {
        const getAccountInfo = async () => {
            const idToken = user ? user!.idToken : null;

            const response = await fetchData("/accountInfo", idToken);
            if (response.status === 200 || response.status === 201) {
                setCompany_id(response.data.company_id);
            }
            else if (response.status === 204 || response.status === 404) {
                Toaster.show(getMessage("errorMessages", "getAccount", messages), 'error');
            }
            else {
                Toaster.show(getMessage("errorMessages", "getAccountFail", messages), 'error');
                console.error('Get company failed');
            }
            setLoading(false);

        }
        getAccountInfo();
    }, [user]);

    useEffect(() => {
        if (search === '') {
            setfilteredModules(modules);
            return;
        }
        const filtered = modules.filter((elem) =>
            elem.name.toLowerCase().includes(search.toLowerCase())
        );
        setfilteredModules(filtered);
        if (filtered.length === 0) {
            Toaster.show(getMessage("errorMessages", "noResults", messages), 'error');
        }

    }, [modules, search]);

    return (
        <Page >
            <div className='h-full miledois:h-screen '>
                <div className='flex flex-col miledois:flex-row gap-10 '>
                    <div className='border-2 border-zinc-400 rounded-2xl p-4 flex flex-col h-60  miledois:w-1/3'>
                        <div className='flex justify-between items-center'>
                            <p className='text-lg font-bold'>{getMessage("other", "totalModules", messages)}</p>
                            {/* <button onClick={() => console.log("more+")} className='flex gap-1'>
                                <p>more</p>
                                <p>+</p>
                            </button>  */}
                        </div>
                        <div className='grid grid-cols-2 justify-between items-center h-full'>
                            <div className='flex justify-center text-[40px]'>
                                {activeModules}/{modules.length}
                            </div>
                            <div className='flex justify-center gap-2 items-center'>
                                <div className={`w-6 h-6 bg-lime-400 text-white rounded-full flex items-center justify-center`}></div>
                                <div className='text-xl'>{getMessage("other", "active", messages)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='border-2 border-zinc-400 rounded-2xl p-4 flex flex-col h-60  miledois:w-1/3'>
                        <div className='flex justify-between items-center'>
                            <p className='text-lg font-bold'>{getMessage("other", "totalSpent", messages)}</p>
                        </div>
                        <div className='grid grid-cols-3 justify-between items-center'>
                            <div className='flex gap-2 flex-wrap'>
                                <p>{totalPrices}€</p>
                                <p>{getMessage("other", "total", messages)}</p>
                            </div>
                            <div>
                                <ResponsiveContainer minHeight={"10em"} height='100%' width='100%'>
                                    <PieChart>
                                        <Pie dataKey="value" paddingAngle={3} data={chartData} fill="#8884d8" innerRadius={"50%"} outerRadius={"100%"} >
                                            {
                                                chartData!.map((entry: any, index: number) => (
                                                    <Cell x={entry.name} y={entry.value} style={{ outline: "none" }} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))
                                            }
                                        </Pie >
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <div className='flex flex-col gap-2 scroll overflow-auto max-h-32 pr-2'>
                                {modules.map((elem, index) => (
                                    <div key={index} className='flex justify-between px-4'>
                                        <p style={{ color: COLORS[index % COLORS.length] }}>{((Number(elem.price) / totalPrices) * 100).toFixed(2)}%</p>
                                        <PiCube size={24} color={COLORS[index % COLORS.length]} title={elem.name} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='border-2 border-zinc-400 rounded-2xl p-4 flex flex-col gap-10 h-60  miledois:w-1/3'>
                        <div className='flex justify-between items-center'>
                            <p className='text-lg font-bold'>{getMessage("other", "modulePrices", messages)}</p>
                        </div>
                        <div>
                            <div className='scroll overflow-auto max-h-32 pr-2'>
                                <div className="min-w-full">
                                    <div className="">
                                        {modules.map((elem, index) => (
                                            <div key={index} className=' text-zinc-500'>
                                                <div className="py-1 flex justify-between  items-center gap-1">
                                                    <div className='flex gap-1 items-center'>
                                                        <PiCube size={24} color={COLORS[index % COLORS.length]} title={elem.name} />
                                                        <div className="text-sm text-black rounded-2xl ">{elem.name}</div>
                                                    </div>
                                                    <div>{elem.price}€</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-10">
                    <div className='flex justify-between items-center gap-6'>
                        <p className='text-lg font-bold'>{getMessage("other", "modules", messages)}</p>
                        {/*TESTING PORPOSES */}
                        {/* <button onClick={addModule} className="bg-dashBlue w-fit text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                            <span>+</span>
                            <span>Module</span>
                        </button> */}
                        {/*TESTING PORPOSES */}
                    </div>
                    <div className="pt-6 flex flex-col cinco:flex-row cinco:items-center gap-4 cinco:gap-2 justify-between pb-6">
                        <div className='flex gap-2 w-full items-center'>
                            {/* <div className='border-2 border-zinc-400 text-zinc-500 rounded-full p-2 px-6'>
                                {lang === "pt" ? "Arquivado" : "Arquived"}
                            </div> */}
                            <div className="w-1/2">
                                <div className=" bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
                                    <input type="text" name="search" placeholder={getMessage("other", "name", messages)} className={`${showSearch ? "w-60 px-4" : "w-0"} transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent`} onChange={handleSearchChange} value={search} />
                                    <button type={showSearch ? "submit" : "button"}>
                                        <div>
                                            <IoIosSearch onClick={() => setShowSearch(!showSearch)} className='text-zinc-500' size={36} title={getMessage("other", "search", messages)} />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <button onClick={() => setShowModal(true)} className="bg-dashBlue w-60 text-white py-2 px-4 rounded-full flex items-center justify-center gap-2 hover:bg-dashBlueDark transition-all duration-300 ease-in-out">
                            <span>+</span>
                            <span>{lang === "pt" ? "Novo Módulo" : "Add Module"}</span>
                        </button> */}
                    </div>
                    {filteredModules && filteredModules.length > 0 ? <div className='scroll overflow-auto max-h-96 w-full'>
                        <div className=' w-full'>
                            <table className=' w-full'>
                                <thead >
                                    <tr className='text-sm text-left text-zinc-500 '>
                                        {[getMessage("other", "name", messages),
                                        getMessage("other", "description", messages),
                                        getMessage("other", "price", messages),
                                        getMessage("other", "type", messages),
                                        getMessage("other", "status", messages),
                                        getMessage("other", "seeDashboard", messages),
                                        getMessage("other", "access", messages)
                                        ].map((header) => (
                                            <th key={header} className="px-4 cinco:px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {filteredModules.map((elem, index) => (
                                        <React.Fragment key={index}>
                                            <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                                <td className="px-6 py-10">
                                                    <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                                </td>
                                                <td className="px-6 py-10  ">
                                                    <div className="text-sm rounded-2xl ">{elem.description}</div>
                                                </td>
                                                <td className="px-6 py-10  ">
                                                    <div className="text-sm rounded-2xl ">{elem.price}€</div>
                                                </td>
                                                <td className="px-6 py-10  ">
                                                    <div className="text-sm rounded-2xl ">{elem.type}</div>
                                                </td>
                                                <td className="px-6 py-10  ">
                                                    <Toggle
                                                        defaultChecked={elem.order_item_status}
                                                        onChange={() => handleorder_item_statusChange(index)} />
                                                </td>
                                                <td className="px-6 py-10">
                                                    <BiRedo onClick={() => window.open(elem.url_deploy_frontend, '_blank')} className='text-indigo-600 cursor-pointer hover:text-indigo-900 transition-all duration-500 ease-in-out' size={40} title={getMessage("other", "seeDashboard", messages)} />
                                                </td>
                                                <td className="px-6 py-10 text-sm font-medium">
                                                    <button
                                                        onClick={() => toggleEditRow(index)}
                                                        className="text-sm font-bold text-indigo-600 hover:text-indigo-900 transition-all duration-500 ease-in-out">
                                                        {getMessage("buttonNames", "show", messages)}
                                                    </button>
                                                </td>

                                            </tr>
                                            <tr className={`${editRowVisible !== index && "hidden"}`}>
                                                <td colSpan={6}>
                                                    <div className="flex flex-col gap-8 pb-10 px-6 w-full">
                                                        <div className='flex gap-8 items-center w-full'>
                                                            <label className='w-32'>{getMessage("other", "accessKey", messages)}</label>
                                                            <input
                                                                className='outline-none w-full h-12 border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                                                id="apiKey"
                                                                name="apiKey"
                                                                value={elem.apiKey || getMessage("other", "newKey", messages)}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className='flex justify-end gap-8'>
                                                            <button onClick={handleCancel} className='bg-zinc-200 text-black py-2 px-4 rounded-lg'>
                                                                {getMessage("buttonNames", "cancel", messages)}
                                                            </button>
                                                            <button onClick={() => GenerateApiKey(elem)} className='bg-dashBlue text-white py-2 px-4 rounded-lg'>
                                                                {getMessage("buttonNames", "generate", messages)}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div> :
                        <div className='flex justify-center items-center h-96 text-zinc-500'>
                            {(search === '' ? getMessage("errorMessages", "noModules", messages) : getMessage("errorMessages", "noResults", messages))}
                        </div>}
                </div>
                <Loading isOpen={loading} />
            </div>
            <Modal isOpen={showModal} handler={() => null}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{getMessage("buttonNames", "addModule", messages)}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={getMessage("buttonNames", "close", messages)} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} className='hidden oito:block' />
                            <p className='text-sm'>
                                {getMessage("other", "selectModules", messages)}
                            </p>
                        </div>
                    </div>
                    <div className='scroll overflow-x-auto max-h-96 pr-2'>

                        <table className="min-w-full">
                            <thead className="">
                                <tr className='text-sm text-left text-zinc-500 '>
                                    {[getMessage("other", "name", messages),
                                    getMessage("other", "name", messages),
                                    getMessage("other", "status", messages),
                                    getMessage("other", "select", messages)].map((header) => (
                                        <th key={header} className="px-4 cinco:px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="">
                                {modules.map((elem, index) => (
                                    <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                        <td className="px-6 py-10">
                                            <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                        </td>
                                        <td className="px-6 py-10  ">
                                            <div className="text-sm rounded-2xl ">{elem.order_item_status}</div>
                                        </td>
                                        <td className="px-6 py-10 text-sm font-medium flex justify-center">
                                            <div
                                                className={`cursor-pointer border flex items-center justify-center border-black rounded-lg w-6 aspect-square ${selectedModules.includes(elem.name) ? 'bg-blue-600 ' : ''}`}
                                                onClick={() => handleAddMember(elem.name)}
                                            >
                                                <FaCheck className='text-white' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex gap-8 justify-end pt-8'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{getMessage("buttonNames", "cancel", messages)}</span>
                        </button>
                        <button className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{getMessage("buttonNames", "add", messages)}</span>
                        </button>

                    </div>
                </div>
            </Modal>
        </Page>

    );
}

