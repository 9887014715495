import React, { useEffect, useMemo, useState } from 'react';
import { PiCube } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import { IoIosClose, IoIosStar } from "react-icons/io";
import Page from '../Page';
import { Module } from '../../interfaces/ModuleMarketplace';
import { useUser } from '../../contexts/userContext';
import { clearCache, fetchData, postData, setCache } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';
import Loading from '../../components/Loading/loading';
import Modal from '../../components/Modal';
import { IoHelpCircleSharp } from 'react-icons/io5';
import { FiUpload } from "react-icons/fi";
import { FaImage } from "react-icons/fa6";
import uploadImagesToS3 from '../../aws/aws';
import { getMessage } from '../../multilanguageSupport/mutilanguage';
import messages from '../../multilanguageSupport/messages/marketPlace.json';

export default function Marketplace() {
    const { user } = useUser();
    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [modules, setModules] = useState<Module[]>([]);
    const [selectedImages, setSelectedImages] = useState<FileList | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [moduleName, setModuleName] = useState<string>('');
    const [moduleDescr, setModuleDescr] = useState<string>('');
    const [modulePrice, setModulePrice] = useState<string>('');
    const [moduleDuration, setModuleDuration] = useState<string>('');
    const [urlDeployFrontend, setUrlDeployFrontend] = useState<string>('');

    function getRandomHexColor(): string {
        let color: string;
        do {
            color = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
        } while (!hasGoodContrast(color) && color === '#FFFFFF');
        return color;
    }

    function hasGoodContrast(hexColor: string): boolean {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        // Calculate luminance
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        // Ensure luminance is high enough for good contrast with black
        return luminance > 128;
    }

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            const maxFiles = 10;
            const maxSizeMB = 10;
            const maxSizeBytes = maxSizeMB * 1024 * 1024;
    
            if (files.length > maxFiles) {
                Toaster.show(getMessage("errorMessages","maxFilesExceeded", messages ), 'error');
                return;
            }
    
            const totalSize = files.reduce((sum, file) => sum + file.size, 0);
            if (totalSize > maxSizeBytes) {
                Toaster.show(getMessage("errorMessages","maxSizeExceeded", messages ), 'error');
                return;
            }
    
            setSelectedImages(event.target.files);
            Toaster.show(getMessage("successMessages","imagesSelected", messages ), 'success');
        }
    };
    
    const ModuleCard = ({ module }: { module: Module }) => {
        return (
            <div
                onClick={() => navigate(`/marketplace/${module.name}`,
                    {
                        state:
                            { id: module.id, name: module.name, reviews: module.reviews, number_reviews: module.reviews_number, desc: module.description, images: module.images }
                    })}
                className={`bg-zinc-100 rounded-2xl p-4 cursor-pointer flex flex-col gap-4`}>
                <div className="flex flex-col gap-4 p-8 bg-zinc-900 rounded-2xl font-[900]">
                    <span className="text-white text-2xl">{getMessage("other","module", messages )}</span>
                    <div style={{ color: module.color }} className='text-3xl'>{module.name}</div>
                </div>
                <div className='flex flex-col gap-2'>
                    <p className='text-lg font-bold'>{module.name}</p>
                    {module.reviews_number > 0 && <div className='flex gap-4'>
                        <div className='flex gap-2 items-center'>
                            <p className='flex items-center h-full text-lg pt-1'>{module.reviews}</p>
                            <IoIosStar size={20} title={getMessage("other","reviews", messages )} />
                            <p className='text-zinc-400'>({module.reviews_number})</p>
                        </div>
                    </div>}
                    <p className='text-sm text-zinc-500'>{module.description}</p>
                </div>
            </div>
        )
    }

    function isValidUrl(url: string): boolean {
        try {
            new URL(url);
            return true;
        } catch (_) {
            return false;
        }
    }


    const addModule = async () => {
        if (!moduleName || !moduleDescr || !modulePrice || !moduleDuration || !urlDeployFrontend) {
            Toaster.show(getMessage("errorMessages","fillAllFields", messages ), 'error');
            return;
        }

        if (isNaN(Number(modulePrice)) || Number(modulePrice) <= 0) {
            Toaster.show(getMessage("errorMessages","priceError", messages ), 'error');
            return false;
        }

        if (isNaN(Number(moduleDuration)) || Number(moduleDuration) <= 0) {
            Toaster.show(getMessage("errorMessages","durationError", messages ), 'error');
            return false;
        }

        if (!isValidUrl(urlDeployFrontend)) {
            Toaster.show(getMessage("errorMessages","invalidUrl", messages ), 'error');
            return false;
        }
        setLoading(true);
        const idToken = user ? user!.idToken : null;
        let uploadedImages: string[] = [];

        if (selectedImages) {
            uploadedImages = await uploadImagesToS3(Array.from(selectedImages), 'modulesimages');
        }
        const newModule = {
            name: moduleName,
            description: moduleDescr,
            price: modulePrice,
            duration: Number(moduleDuration),
            images: uploadedImages,
            url_deploy_frontend: urlDeployFrontend,
        };
        const response = await postData("/api/modules/", newModule, idToken);
        if (response.status === 200 || response.status === 201) {
            const newModule = { id: response.data.feature.id, name: moduleName, reviews: 0, reviews_number: 0, description: moduleDescr, images: uploadedImages || [], color: getRandomHexColor() };
            const updatedModules = [...modules, { ...newModule }];
            clearCache("/api/modules/all");
            setModules(updatedModules);
            Toaster.show(getMessage("successMessages","moduleAddedMarket", messages ), 'success');
            setShowModal(false);
        } else {
            Toaster.show(getMessage("errorMessages","addModuleMarketFail", messages ), 'error');
            console.error('Add module failed');
        }
        setLoading(false);
    }

    const toggleCancel = () => {
        setShowModal(false);
        setModuleName('');
        setSelectedImages(null);
    }


    useEffect(() => {
        const getModules = async () => {
            const idToken = user ? user!.idToken : null;

            const response = await fetchData("/api/modules/all", idToken);
            if (response.status === 200 || response.status === 201) {
                console.log("Modules market ", response.data);
                const modulesWithColor = response.data.map((module: Module) => ({
                    ...module,
                    color: module.color || getRandomHexColor(),
                }));
                const cacheData = {
                    data: modulesWithColor,
                    status: response.status,
                };
                setCache("/api/modules/all", cacheData);
                setModules(modulesWithColor);
            }
            else if (response.status === 204 || response.status === 404) {
                Toaster.show(getMessage("errorMessages","noModulesAvailable", messages ), 'error');
            }
            else {
                Toaster.show(getMessage("errorMessages","noModulesAvailable", messages ), 'error');
                console.error('Get modules market failed');
            }
            setLoading(false);

        }
        getModules();
    }, [user]);

    return (
        <Page >
            <div className='h-full min-h-screen flex flex-col gap-10'>
                <div className='flex flex-col gap-10'>
                    <p className='text-2xl font-bold'>{getMessage("other","marketplace", messages )}</p>
                    <div className='relative rounded-2xl text-white flex flex-col gap-8 p-10 bg-gradient-to-r from-black to-blue-600 overflow-hidden'>
                        <p className='text-2xl font-bold'>{getMessage("other","favourites", messages )}</p>
                        <p className='text-lg'>{getMessage("other","topModules", messages )}</p>
                        <button className='border w-fit border-solid border-white px-4 py-2 rounded-full'>{getMessage("other","collection", messages )}</button>
                        <div className='hidden nove:block absolute right-[-4rem]'>
                            <PiCube className='text-lime-500' size={200} />
                        </div>
                        <div className='hidden nove:block absolute right-[12rem] bottom-[-7rem]'>
                            <PiCube className='text-orange-300' size={200} />
                        </div>
                        <div className='hidden miledois:block absolute right-[17rem] top-[-5rem]'>
                            <PiCube className='text-blue-300' size={200} />
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-end'>
                    <button onClick={() => setShowModal(true)} className="bg-dashBlue w-fit text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <span>{getMessage("buttonNames","addModule", messages )}</span>
                    </button>
                </div>
                <div className='grid grid-cols-1 nove:grid-cols-2 miledois:grid-cols-3 milecinco:grid-cols-4 gap-10'>
                    {modules.map((module) => (
                        <ModuleCard key={module.id} module={module} />
                    ))}
                </div>
            </div>
            <Modal isOpen={showModal} handler={addModule}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{getMessage("buttonNames","addModule", messages )}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={getMessage("buttonNames","cancel", messages )} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <div><IoHelpCircleSharp size={24} title={getMessage("other","help", messages )} className='hidden oito:block'/></div>
                            <p className='text-sm'>{getMessage("other","helpText", messages )} 
                            </p>
                        </div>
                        <div className='py-8 flex flex-col sete:flex-row justify-around sete:items-center gap-4'>
                            <div className='flex flex-col gap-8'>
                                <div className='flex flex-col gap-2'>
                                    <label htmlFor="moduleName" className='text-sm'>{getMessage("other","name", messages )}</label>
                                    <input
                                        className="w-64 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder={`| ${getMessage("other","name", messages )}`}
                                        onChange={(e) => setModuleName(e.target.value)}
                                        value={moduleName}
                                    />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label htmlFor="moduleDescription" className='text-sm'>{getMessage("other","description", messages )}</label>
                                    <textarea
                                        className="w-64 outline-none h-12 px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        placeholder={`| ${getMessage("other","description", messages )}`}
                                        onChange={(e) => setModuleDescr(e.target.value)}
                                        value={moduleDescr}
                                    />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label htmlFor="modulePrice" className='text-sm'>{getMessage("other","price", messages )}</label>
                                    <input
                                        className="w-64 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder={`| ${getMessage("other","price", messages )}`}
                                        onChange={(e) => setModulePrice(e.target.value)}
                                        value={modulePrice}
                                    />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label htmlFor="moduleDuration" className='text-sm'>{getMessage("other","duration", messages )}</label>
                                    <input
                                        className="w-64 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder={`| ${getMessage("other","duration", messages )}`}
                                        onChange={(e) => setModuleDuration(e.target.value)}
                                        value={moduleDuration}
                                    />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label htmlFor="moduleDuration" className='text-sm'>{getMessage("other","urlDeploy", messages )}</label>
                                    <input
                                        className="w-64 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder={`| ${getMessage("other","urlDeploy", messages )}`}
                                        onChange={(e) => setUrlDeployFrontend(e.target.value)}
                                        value={urlDeployFrontend}
                                    />
                                </div>
                                <div className="relative">
                                    <label
                                        title={getMessage("other","uploadImages", messages )}
                                        htmlFor="buttonImages"
                                        className="relative z-10 cursor-pointer hover:scale-105 transition-transform duration-200 flex bg-dashBlue px-4 py-2 rounded-xl gap-3 min-w-40"
                                    >
                                        <span className="relative flex w-full gap-3 text-sm font-bold text-white cursor-pointer justify-center">
                                            {getMessage("other","uploadImages", messages )}
                                            <FiUpload color="white" size={20} title={getMessage("other","uploadImages", messages )} />
                                            <FaImage color="white" size={20} title={getMessage("other","uploadImages", messages )}/>
                                        </span>
                                    </label>
                                    <input
                                        hidden
                                        type="file"
                                        name="buttonImages"
                                        id="buttonImages"
                                        className="w-full"
                                        accept=".png, .jpg"
                                        multiple
                                        onChange={handleImageUpload}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-8 justify-end'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{getMessage("buttonNames","cancel", messages )}</span>
                        </button>
                        <button onClick={addModule} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{getMessage("buttonNames","add", messages )}</span>
                        </button>

                    </div>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </Page>

    );
}

