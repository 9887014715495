import React, { useState } from 'react';
import Page from '../Page';
import BillingNavbar from './billingNavbar';
import { getMessage } from '../../multilanguageSupport/mutilanguage';
import messages from '../../multilanguageSupport/messages/billing.json';

export default function InvoiceDocs() {
    const [email, setEmail] = useState("email@example.com");
    const [emailNotification, setEmailNotification] = useState(true);
    return (
        <Page>
            <BillingNavbar>
                <div className='flex flex-col gap-10 w-full'>
                    <div className='border-2 border-zinc-200 rounded-lg p-8 flex flex-col gap-2 w-full'>
                        <p className='text-xl font-bold'>{getMessage("other","invoiceTitle", messages )}</p>
                        <p >{getMessage("other","invoiceSubtitle", messages  )}</p>
                    </div>
                    <div className='border-2 border-zinc-200 rounded-lg mil:flex gap-2 w-full'>
                        <div className=' p-8  mil:w-[70%]'>
                            <p className='text-xl font-bold'>{getMessage("other","Billable email preferences", messages  )}</p>
                            <p className=' text-zinc-500'>{email}</p>
                            <div className='flex pt-10 gap-4'>
                                <label className="inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="sr-only peer"
                                        checked={emailNotification}
                                        onClick={() => setEmailNotification(!emailNotification)}
                                    />
                                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-400">
                                    </div>
                                </label>
                                <p className='text-zinc-400'>{getMessage("other","Receive invoice atachements",  messages )}</p>
                            </div>
                        </div>
                        <div className='bg-zinc-100 mil:w-[30%] p-4 border-t-2 mil:border-t-0 mil:border-l-2 border-zinc-200 rounded-lg flex justify-center items-center'>
                            <button className='bg-indigo-400 text-white py-2 px-6 rounded-lg'>{getMessage("other","Change email",  messages )}</button>
                        </div>
                    </div>
                </div>
            </BillingNavbar>
        </Page>

    );
}

